import React from 'react'
import {
  CtaBar,
  AvailabilityForm,
  LeshenPhoneCTA,
} from '@leshen/gatsby-theme-leshen'

const SeoCtaBar = () => {
  return (
    <CtaBar
      backgroundColor="primary"
      sticky
      leftBlock={<LeshenPhoneCTA color="white">Call</LeshenPhoneCTA>}
      middleBlock={
        <AvailabilityForm
          buttonColor="secondary"
          buttonText="Submit"
          placeholderText="Zip Code"
        />
      }
    />
  )
}

export default SeoCtaBar
