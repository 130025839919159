/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'

import {
  Accordion,
  Carousel,
  Brandy,
  Image,
  Link,
  Stack,
  Column,
  Columns,
  AvailabilityForm,
  VariableContent,
  SplitContent,
  SVGImage,
  LeshenPhoneCTA,
} from '@leshen/gatsby-theme-leshen'

import { Typography } from '@leshen/ui'
import { graphql } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'
import SeoCtaBar from '../components/SeoCtaBar'

const CityTemplate = ({ data }) => {
  const { Page_Path, State, City, Promo_Code, Meta_Description, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <SplitContent
            backgroundColor="tertiary"
            alignImageToBottom={false}
            image={
              <SVGImage
                svg={data.heroDesktop.cloudinary[0].svg.code}
                presentationWidth={
                  data.heroDesktop.cloudinary[0].svg.presentationWidth
                }
              />
            }
            mobileImage={
              <SVGImage
                svg={data.heroMobile.cloudinary[0].svg.code}
                presentationWidth={
                  data.heroMobile.cloudinary[0].svg.presentationWidth
                }
              />
            }
            mainContent={
              <>
                <Typography variant="h1">
                  Quantum Fiber internet in {City}, {State_Name}
                </Typography>
                <Typography variant="h6">
                  Enter your zip code to see if fast, fiber internet is
                  available at your home or business.
                </Typography>
                <AvailabilityForm
                  label="Check Availability Near Me"
                  placeholderText="ZIP Code"
                  buttonText="View Plans"
                  buttonColor="primary"
                />
              </>
            }
          />
          <SeoCtaBar />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Why fiber internet in {City}?
                </Typography>
                <Typography>
                  Fiber internet, sometimes called fiber-optic internet, offers
                  faster speeds than cable, DSL, and dial-up—with Quantum Fiber,
                  powered by pulses of light, you can achieve speeds of up to
                  940 Mbps in some areas. Not only that—fiber internet offers
                  symmetrical upload and download speeds, better for working and
                  gaming online.
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor="light"
            customLink={null}
          >
            <Columns
              className=""
              leftAligned
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
              layout="Left Icon"
            >
              <Column
                backgroundColor="tertiary"
                image={
                  <SVGImage
                    svg={data.speedometer.cloudinary[0].svg.code}
                    presentationWidth={
                      data.speedometer.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h4">Speed</Typography>
                <Typography variant="">
                  Speeds up to 940 Mbps mean faster streaming, smoother surfing,
                  and high-performance gaming.
                </Typography>
                <Brandy
                  text="Speed may not be available in your area."
                  variant="legal"
                />
              </Column>
              <Column
                backgroundColor="tertiary"
                image={
                  <SVGImage
                    svg={data.router.cloudinary[0].svg.code}
                    presentationWidth={
                      data.router.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h4">Reliability</Typography>
                <Typography variant="">
                  Don’t worry about your favorite show cutting off
                  mid-stream—Quantum fiber is ultra-fast all day, all night with
                  99.9% reliability*.
                </Typography>
                <Brandy
                  text="*Based on network uptime or availability."
                  variant="legal"
                />
              </Column>
              <Column
                backgroundColor="tertiary"
                image={
                  <SVGImage
                    svg={data.contract.cloudinary[0].svg.code}
                    presentationWidth={
                      data.contract.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h4">No Annual Contracts</Typography>
                <Typography variant="">
                  With no annual contracts to tie you down, you only need to
                  stick with Quantum Fiber if you’re happy. Satisfaction
                  guaranteed.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Do more with Quantum Fiber Services in {City}
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            breadcrumbProps={null}
            customLink={null}
          >
            <Carousel>
              <div>
                <Image
                  data={data.carousel1.cloudinary[0].gatsbyImageData}
                  isImageCritical
                />
                <br />
                <br />
                <Typography variant="h4">Streaming</Typography>
                <Typography variant="">
                  Quantum Fiber’s speeds mean smoother streaming. After all,
                  what’s worse than a show buffering at the best part?
                </Typography>
                <Brandy
                  symbol=""
                  text="Streaming service is billed separately from Quantum Fiber. Restrictions apply."
                  variant="legal"
                  modal={false}
                />
              </div>
              <div>
                <Image
                  data={data.carousel2.cloudinary[0].gatsbyImageData}
                  isImageCritical
                />
                <br />
                <br />
                <Typography variant="h4">360 WiFi</Typography>
                <Typography variant="">
                  Quantum Fiber 360 WiFi helps keep your network secure. Whether
                  you’re a residential or business customer, stay protected all
                  the way around.
                </Typography>
              </div>
              <div>
                <Image
                  data={data.carousel3.cloudinary[0].gatsbyImageData}
                  isImageCritical
                />
                <br />
                <br />
                <Typography variant="h4">Small Business</Typography>
                <Typography variant="">
                  Speed up your small business connections with Quantum Fiber.
                  Better performance means happier, more efficient employees and
                  more satisfied customers.
                </Typography>
              </div>
              <div>
                <Image
                  data={data.carousel4.cloudinary[0].gatsbyImageData}
                  isImageCritical
                />
                <br />
                <br />
                <Typography variant="h4">Connected Voice</Typography>
                <Typography variant="">
                  No matter how you message, meet, or call, Connected Voice has
                  you covered. Features include unlimited local and
                  long-distance calling and custom call screening.
                </Typography>
              </div>
            </Carousel>
          </VariableContent>
          <SplitContent
            backgroundColor="light"
            alignImageToBottom={false}
            image={
              <Image
                data={data.customerRepDesktop.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.customerRepMobile.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Need help? Quantum Fiber Customer Service is here
                </Typography>
                <Typography variant="">
                  Quantum Fiber offers a dedicated Fiber Customer Success Team
                  available seven days a week from 7AM - 11PM Central Time.
                </Typography>
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            mainContent={
              <Typography variant="h2">Frequently Asked Questions</Typography>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion
                items={[
                  {
                    title: `Where is quantum fiber available?                    `,
                    content: (
                      <div>
                        <Typography variant="feature">
                          Quantum Fiber is available in certain parts of
                          Washington, Oregon, Nevada, Utah, Arizona, Colorado,
                          Nebraska, Minnesota, Wisconsin, and Florida.{' '}
                          <Link to="/availability">Check availability</Link> to
                          see if your city is covered—new fiber infrastructure
                          is constantly added.
                        </Typography>
                      </div>
                    ),
                  },
                  {
                    title: `Is Quantum Fiber good internet?                    `,
                    content: (
                      <div>
                        <Typography variant="feature">
                          Quantum Fiber uses fiber-optic technology to deliver
                          data using pulses of light, allowing for faster speeds
                          than cable, DSL, and especially dial-up. With Quantum
                          Fiber you can achieve up to 940 Mbps* symmetrical
                          upload and download speeds on a network running at
                          99.9% reliability<sup>**</sup>.
                        </Typography>
                        <Brandy
                          symbol=""
                          text="<sup>*</sup>Speed may not be available in your area."
                          variant="legal"
                          modal={false}
                        />
                        <Brandy
                          symbol=""
                          text="<sup>**</sup>Based on network uptime or availability."
                          variant="legal"
                          modal={false}
                        />
                      </div>
                    ),
                  },
                  {
                    title: `How do I know if fiber will be available in my area?`,
                    content: (
                      <div>
                        <Typography variant="feature">
                          Internet service providers across the nation are
                          expanding their fiber infrastructure. To find out if
                          Quantum Fiber internet is available in {City}, check
                          out our{' '}
                          <Link to="/availability">availability page</Link>.
                        </Typography>
                      </div>
                    ),
                  },
                  {
                    title: `How much does Quantum Fiber Cost?`,
                    content: (
                      <div>
                        <Typography variant="feature">
                          Prices vary according to location and availability.
                          Quantum Fiber offers two high-speed internet packages.
                          The cheaper package offers speeds up to 240 Mbps and
                          the more expensive package offers speeds up to 940
                          Mbps.
                        </Typography>
                      </div>
                    ),
                  },
                ]}
              />
            </Stack>
          </VariableContent>
        </>
      }
      // footer={<Footer disclaimers={[data?.disclaimerFooter1]} />}
    />
  )
}

CityTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default CityTemplate

export const query = graphql`
  query CityTemplateQuery($pagePath: String) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerGetquantumfiberAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          Page_Path
          State
          City
          Promo_Code
          Meta_Description
          Meta_Title
        }
      }
    }
    heroDesktop: contentfulMedia(
      contentful_id: { eq: "2gzTtIFpcVKHiLnOYTYg8l" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    heroMobile: contentfulMedia(
      contentful_id: { eq: "2gzTtIFpcVKHiLnOYTYg8l" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    speedometer: contentfulMedia(
      contentful_id: { eq: "1SCnyT1IPUdvRo0plV7ivB" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    router: contentfulMedia(contentful_id: { eq: "2bIJKffgsNk5t1vAAbTqPV" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    contract: contentfulMedia(contentful_id: { eq: "6szP26t3J6izg1KUAA4XKB" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    carousel1: contentfulMedia(
      contentful_id: { eq: "49btnvU5RdrMktLdTrf7ki" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 1000)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    carousel2: contentfulMedia(
      contentful_id: { eq: "6yvb7JUUq5OpqozwSZw6u1" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 1000)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    carousel3: contentfulMedia(
      contentful_id: { eq: "1cdDSdF9YfaQEgfbKBl7YR" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 1000)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    carousel4: contentfulMedia(
      contentful_id: { eq: "6ym8VuYhyUvhvwN8YooUif" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 1000)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    customerRepDesktop: contentfulMedia(
      contentful_id: { eq: "314egJTWkl8WxivbewoaQU" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 568)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    customerRepMobile: contentfulMedia(
      contentful_id: { eq: "4HnEHL62lE0GNpmgjMRYeX" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 400)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    disclaimerFooter1: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-qf-allrightsreserved" }
    ) {
      id
      text
      symbol
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "87ee343a-7023-5a89-a4cb-0790ad2ab2e7" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "acff8862-7da3-5e17-9f83-5a564d73a494" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
